import React, { useEffect, useState } from "react";
import cx from "classnames";
import Imgix from "react-imgix";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import styles from "./AuctionDashboardHero.module.scss";
import { User } from "../../../../../types/User";
import {
  selectAvailableMarkets,
  selectInstagramAccountsLoading,
  selectUserInfo,
} from "../../../../../store/user/selectors";
import { useAppDispatch } from "../../../../../store";
import {
  selectAuctionCounts,
  selectAuctions,
} from "../../../../../store/auctions/selectors";
import { AuctionResults, Counts } from "../../../../../store/auctions/actions";
import { Market } from "../../../../../types/Market";
import { PathTypes } from "../NewAuctionCreation/Types/PathTypes";
import { setPath } from "../../../../../store/auctionModal/reducer";
import QuickStartGuide from "./QuickStartGuide";

const PlaceholderImage =
  "https://imgix.cosmicjs.com/726a17f0-52d5-11ec-9aff-3d50541531a0-Placeholder.png";
const AuctionDashboardHero = () => {
  const dispatch = useAppDispatch();
  const [finishedQuickStart, setFinishedQuickStart] = useState(false);
  const instagramAccountsLoading = useSelector((state) =>
    selectInstagramAccountsLoading(state)
  );
  const userInfo = useSelector((state) => selectUserInfo(state)) as User;
  const auctions = useSelector((state) =>
    selectAuctions(state)
  ) as AuctionResults;
  const counts = useSelector((state) => selectAuctionCounts(state)) as Counts;
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];

  useEffect(() => {
    if (Cookies.get("tasksCompletedAt")) {
      setFinishedQuickStart(true);
    }
    dispatch(setPath(PathTypes.DASHBOARD));
  }, []);

  const handleAuctionCreation = () => {
    dispatch(setPath(PathTypes.DASHBOARD));
    if (availableMarkets.length === 0) {
      // No markets they need to connect to one
      navigate("/dashboard/getting-started/connect-instagram/", {
        state: {
          fromPath: PathTypes.DASHBOARD,
          originationPath: PathTypes.DASHBOARD,
          toPath: PathTypes.AUCTION_CREATION_MAIN,
        },
      });
    } else if (availableMarkets.length === 1) {
      // Only one market go straight to new auction page
      navigate("/dashboard/auctions/new/", {
        state: {
          fromPath: PathTypes.DASHBOARD,
          originationPath: PathTypes.DASHBOARD,
        },
      });
    } else {
      // Multiple markets go to new auction page (will need to select market)
      navigate("/dashboard/auctions/new/", {
        state: {
          fromPath: PathTypes.DASHBOARD,
          originationPath: PathTypes.DASHBOARD,
        },
      });
    }
  };

  const connectCompleted = availableMarkets.length > 0;
  const createCompleted =
    connectCompleted && auctions && auctions.data.length > 0;
  const completeAuctionCompleted = createCompleted && counts?.finish > 0;

  useEffect(() => {
    if (completeAuctionCompleted) {
      // Store tasksCompletedAt as a date in a cookie
      const tasksCompletedAt = new Date();
      Cookies.set("tasksCompletedAt", tasksCompletedAt);
    }
  }, [completeAuctionCompleted]);

  return (
    <div className={cx([styles.auctionDashboardHeroContainer, "px-5"])}>
      <div className={styles.imageTempPlaceholder}>
        <Imgix
          src={`${PlaceholderImage}?auto=format`}
          height={138}
          width={138}
          htmlAttributes={{
            alt: "Placeholder Image",
            src: `${PlaceholderImage}?auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
      </div>
      <h3>
        {userInfo &&
          userInfo.data &&
          `${userInfo.data.first_name || ""} ${userInfo.data.last_name || ""}`}
      </h3>
      <div className="flex flex-row items-start justify-center mx-auto px-0 mt-5">
        <div className={styles.auctionCol}>
          <h4>{counts && counts.publish ? counts.publish : 0}</h4>
          <p>LIVE AUCTIONS</p>
        </div>
        <div className={cx([styles.auctionCol, styles.borderedCol])}>
          <h4>
            {auctions && auctions.meta && auctions.meta.total_entries
              ? auctions.meta.total_entries
              : 0}
          </h4>
          <p>AUCTIONS</p>
        </div>
        <div className={styles.auctionCol}>
          <button
            type="button"
            disabled={instagramAccountsLoading}
            aria-label="Create An Auction"
            className={styles.addAuction}
            onClick={() => handleAuctionCreation()}
          />
          <p>CREATE AUCTION</p>
        </div>
      </div>
      {/* Ad Steps here */}
      {!finishedQuickStart && <QuickStartGuide />}
    </div>
  );
};

export default AuctionDashboardHero;
