import React from "react";

const ArrowIcon = ({ className }) => (
  <svg
    className={className}
    width="61"
    height="59"
    viewBox="0 0 61 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.3177 32.888H0V26.112H48.3177L27.4715 4.79141L32.1563 0L61 29.5L32.1563 59L27.4715 54.2086L48.3177 32.888Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowIcon;
