export const selectIndividualAuction = (state) =>
  state.individualAuction.auction;
export const isIndividualAuctionModalOpen = (state) =>
  state.individualAuction.openIndividualAuction;
export const selectIndividualAuctionId = (state) =>
  state.individualAuction.auctionId;
export const isIndividualAuctionLoading = (state) =>
  state.individualAuction.loading;
export const selectNavigationPath = (state) =>
  state.individualAuction.navigationPath;
export const selectHasSuccessfullyDeleted = (state) =>
  state.individualAuction.successfullyDeleted;
export const selectSuccessfullySelectedWinner = (state) =>
  state.individualAuction.successfullySelectedWinner;
export const selectIndividualAuctionError = (state) =>
  state.individualAuction.error;
