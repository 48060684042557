import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { AuthProvider } from "../../components/Auth/auth";
import DashboardLayout from "../../components/DashboardLayout";
import AuctionDashboardHero from "../../components/PageComponents/Dashboard/Auctions/DashboardHome/AuctionDashboardHero";
import { isLoading, selectAuctions } from "../../store/auctions/selectors";
import { isIndividualAuctionLoading } from "../../store/individualAuction/selectors";
import { isStoreLoading } from "../../store/stores/selectors";
import { getIsAuthLoading } from "../../store/auth/selectors";
import {
  selectInstagramAccountsLoading,
  selectUserInfo,
  selectUserInfoLoading,
} from "../../store/user/selectors";
import {
  AuctionResults,
  fetchAuctionCounts,
  fetchAuctions,
} from "../../store/auctions/actions";
import {
  getUserInfo,
  getUserInstagramAccounts,
} from "../../store/user/actions";
import { useAppDispatch } from "../../store";
import { User } from "../../types/User";
import LoadingSmall from "../../components/Loading/LoadingSmall";
import FullScreenLoading from "../../components/Loading/FullScreenLoading";
import { setPath } from "../../store/auctionModal/reducer";
import { PathTypes } from "../../components/PageComponents/Dashboard/Auctions/NewAuctionCreation/Types/PathTypes";

const AuctionList = React.lazy(
  () =>
    import(
      "../../components/PageComponents/Dashboard/Auctions/AuctionList/AuctionList"
    )
);

const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const auctions = useSelector((state) =>
    selectAuctions(state)
  ) as AuctionResults;
  const userInfo = useSelector((state) => selectUserInfo(state)) as User;
  const loading = useSelector(
    (state) =>
      isLoading(state) ||
      isIndividualAuctionLoading(state) ||
      isStoreLoading(state) ||
      getIsAuthLoading(state) ||
      selectUserInfoLoading(state) ||
      selectInstagramAccountsLoading(state)
  );
  const [isMounted, setIsMounted] = useState(false);
  const [requestingAuctions, setRequestingAuctions] = useState(false);

  useEffect(() => {
    if (auctions && requestingAuctions) {
      setRequestingAuctions(false);
    }
  }, [auctions, requestingAuctions]);

  useEffect(() => {
    if (userInfo && userInfo.data && !requestingAuctions) {
      dispatch(
        fetchAuctions({
          id: userInfo.data.last_store_id,
          page_size: 6,
          sort_order: "desc",
          sort: "inserted_at",
        })
      );
      dispatch(
        fetchAuctionCounts({
          storeId: userInfo.data.last_store_id,
        })
      );
      setRequestingAuctions(true);
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getUserInstagramAccounts());
    dispatch(setPath(PathTypes.DASHBOARD));
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const { success } = queryString.parse(window.location.search);
      if (success === "payment_account") {
        window.close();
      }
    }
    setIsMounted(true);
  }, []);

  return (
    <AuthProvider>
      <DashboardLayout hideFooter title="Bidddy - Dashboard Overview">
        <div className="flex flex-col w-full overflow-x-hidden">
          {!loading && isMounted && (
            <>
              <React.Suspense fallback={<LoadingSmall />}>
                <AuctionDashboardHero />
                <AuctionList />
              </React.Suspense>
            </>
          )}
        </div>
        {loading && <FullScreenLoading />}
      </DashboardLayout>
    </AuthProvider>
  );
};

export default DashboardPage;
