import React, { useEffect } from "react";
import cn from "classnames";
import { Link } from "gatsby";
import { useSelector } from "react-redux";
import styles from "./AuctionDashboardHero.module.scss";
import ArrowIcon from "../../../../Icons/ArrowIcon";
import { selectAvailableMarkets } from "../../../../../store/user/selectors";
import {
  selectAuctionCounts,
  selectAuctions,
} from "../../../../../store/auctions/selectors";
import { AuctionResults, Counts } from "../../../../../store/auctions/actions";
import { Market } from "../../../../../types/Market";

const QuickStartGuide = () => {
  const counts = useSelector((state) => selectAuctionCounts(state)) as Counts;
  const auctions = useSelector((state) =>
    selectAuctions(state)
  ) as AuctionResults;
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];

  const connectActivated = availableMarkets.length === 0;
  const connectCompleted = availableMarkets.length > 0;
  const createActivated =
    connectCompleted && (!auctions || auctions.data.length === 0);
  const createCompleted =
    connectCompleted && auctions && auctions.data.length > 0;

  const completeAuctionActivated = createCompleted && counts?.finish === 0;
  const completeAuctionCompleted = createCompleted && counts?.finish > 0;

  return (
    <div
      className={cn(
        "rounded-lg bg-white flex flex-col w-full max-w-6xl p-7 mt-12 mb-8",
        styles.quickGuideContainer
      )}
    >
      <div className="flex flex-row justify-between items-center mb-5">
        <h2 className="font-inter font-bold text-3xl text-bidddyAltGray">
          Your quick start guide
        </h2>
        <Link
          className="text-base font-inter text-bidddyAltGray no-underline hover:opacity-75"
          to="/learn"
        >
          Learn More 🎓
        </Link>
      </div>
      <div className="flex flex-col justify-between items-center w-full">
        <Link
          to="/dashboard/getting-started/connecting-bidddy/"
          className={cn(
            "flex flex-row items-center justify-between relative w-full",
            styles.quickGuideItem,
            { [styles.activated]: connectActivated },
            { [styles.completed]: connectCompleted }
          )}
        >
          <span>1</span>
          <div className="flex flex-col ml-12">
            <h5 className="mb-1">Connect to Instagram</h5>
            <p className="text-sm">
              Link your Instagram account to your brand new Bidddy profile ✨
            </p>
          </div>
          <ArrowIcon className="text-bidddyGray" />
        </Link>
        <Link
          to="/learn/how-to-create-an-auction-on-instagram"
          className={cn(
            "flex flex-row items-center justify-between relative w-full mt-5",
            styles.quickGuideItem,
            {
              [styles.activated]: createActivated,
            },
            {
              [styles.completed]: createCompleted,
            }
          )}
        >
          <span>2</span>
          <div className="flex flex-col ml-12">
            <h5 className="mb-1">Create an auction</h5>
            <p className="text-sm">
              Start an auction for an existing post, or schedule a new one in
              under a minute!
            </p>
          </div>
          <ArrowIcon className="text-bidddyGray" />
        </Link>
        <div
          role="button"
          tabIndex={-1}
          className={cn(
            "flex flex-row items-center justify-between relative w-full mt-5",
            styles.quickGuideItem,
            styles.hideTail,
            { [styles.activated]: completeAuctionActivated },
            { [styles.completed]: completeAuctionCompleted }
          )}
          onClick={() => {}}
          onKeyDown={() => {}}
        >
          <span>3</span>
          <div className="flex flex-col ml-12">
            <h5 className="mb-1">Complete your first auction</h5>
            <p className="text-sm">
              This is the beginning of something amazing. Learn how to get more
              bids, bring in more engagement and reach more people for your next
              auction!
            </p>
          </div>
          <ArrowIcon className="text-bidddyGray" />
        </div>
      </div>
    </div>
  );
};

export default QuickStartGuide;
