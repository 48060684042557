export const getIsAuthLoading = (state): boolean => state.auth.loading;
export const getAuthErrors = (state) => state.auth.errors;
export const getAuthSuccess = (state): boolean => state.auth.success;
export const getCurrentStep = (state) => state.auth.currentStep;
export const getCurrentConnectionStep = (state) =>
  state.auth.currentConnectionStep;
export const getLastConnectionStep = (state) => state.auth.lastConnectionStep;
export const getIsSliding = (state) => state.auth.isSliding;

export const getCurrentPaymentStep = (state) => state.auth.currentPaymentStep;
